import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/code/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_n5vIFAvtHZA5gBXuPMVRvtBRU8UfAXJe8qfZOIpZxWQ from "/code/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE from "/code/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/code/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/code/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/code/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/code/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/code/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/code/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/code/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/code/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/code/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/code/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/code/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/code/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/code/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY from "/code/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w from "/code/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o from "/code/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg from "/code/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_HVC5_pBnOqXuNIJWDoUz0EW8qR41YmLCqZ9a_t4Lvs8 from "/code/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_84QbCrEJidQfHX79evFy6rRgOFjwLXHugOxbWoIVeIQ from "/code/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/code/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import apiFetch_ZJUcolkka68DTlFRDIgq2uLlCnEQ6t80BFp5SkRCucE from "/code/app/plugins/apiFetch.ts";
import formatDate_WGzf_txM6Poj1SHgwsg8ZgLCkMjhTWGWMx_ACZjMpac from "/code/app/plugins/formatDate.ts";
import matomo_client_BdTsvle0BpB_gdI8Wg14_qwwYcMw75038v2vv2QWi78 from "/code/app/plugins/matomo.client.ts";
import openWindow_j6fv6qzl5_qnkXN3La_uD_4Lav5AjLT0WoWtOPDv9_Q from "/code/app/plugins/openWindow.ts";
import translate_U_1wgPPr2Jr4JMcGxAyELLmbD2yUjhyv6snF3v5ad_s from "/code/app/plugins/translate.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/code/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
import _0_routeRules_3p7F2AZYQSP_eJRsw5nLkf3zyZXPOFcTrXNpZlBwROM from "/code/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import vuetify_nuxt_plugin_client_vhPL_4E3qi2WkYEd3Y5XADFMLa2GBXOaOwhkLQcelWw from "/code/node_modules/.cache/nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  vuetify_i18n_n5vIFAvtHZA5gBXuPMVRvtBRU8UfAXJe8qfZOIpZxWQ,
  vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY,
  siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w,
  inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o,
  titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg,
  defaultsWaitI18n_HVC5_pBnOqXuNIJWDoUz0EW8qR41YmLCqZ9a_t4Lvs8,
  i18n_84QbCrEJidQfHX79evFy6rRgOFjwLXHugOxbWoIVeIQ,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  apiFetch_ZJUcolkka68DTlFRDIgq2uLlCnEQ6t80BFp5SkRCucE,
  formatDate_WGzf_txM6Poj1SHgwsg8ZgLCkMjhTWGWMx_ACZjMpac,
  matomo_client_BdTsvle0BpB_gdI8Wg14_qwwYcMw75038v2vv2QWi78,
  openWindow_j6fv6qzl5_qnkXN3La_uD_4Lav5AjLT0WoWtOPDv9_Q,
  translate_U_1wgPPr2Jr4JMcGxAyELLmbD2yUjhyv6snF3v5ad_s,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs,
  _0_routeRules_3p7F2AZYQSP_eJRsw5nLkf3zyZXPOFcTrXNpZlBwROM,
  vuetify_nuxt_plugin_client_vhPL_4E3qi2WkYEd3Y5XADFMLa2GBXOaOwhkLQcelWw
]