
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45lo7Hh1S6XF9hCjtTA9S6YuDr8BMMtEs0lp7lFcoIugMeta } from "/code/app/pages/about.vue?macro=true";
import { default as indexnGMQ_45igP6cws8hWcw5uGdHZr9Kemt0P7rMbYdr_P9ccMeta } from "/code/app/pages/index.vue?macro=true";
import { default as doisucWGk1nOgLtTBkqjg9nXmQv8O412nU0ae69hRp5DyngMeta } from "/code/app/pages/search/dois.vue?macro=true";
import { default as taxaKjsmN2ol3O3QAWgsWNogLsiQzmlqKAe1q82SavJRQ8EMeta } from "/code/app/pages/search/taxa.vue?macro=true";
import { default as areasiIFzgkIGX8XOYqrkmVLinJBEpNgYWzamUzN5RVdr8BQMeta } from "/code/app/pages/search/areas.vue?macro=true";
import { default as filesXTEsxB8X_45oGkVC91aVs9rnX_n00n2gKLQeeT_QBOCSEMeta } from "/code/app/pages/search/files.vue?macro=true";
import { default as rockshzAJtDvNOWGF__UQtuGptWWz7qSeKDbdx2QSYM2dnPAMeta } from "/code/app/pages/search/rocks.vue?macro=true";
import { default as sitesEPxWflpRmN4iQWZumkdagLMtpP60haoBYZTygXzD6QAMeta } from "/code/app/pages/search/sites.vue?macro=true";
import { default as photosZraFmyqM_b4Rdi1fKIM6IW2ORHlKuG6cdic5UymCUQoMeta } from "/code/app/pages/search/photos.vue?macro=true";
import { default as samplesfs_mvazX_45ycS9ZdJEOIdDOk2xvNWlbmKzkJUs1BogJoMeta } from "/code/app/pages/search/samples.vue?macro=true";
import { default as analyseszZ3VOH51Pvv4UNk2kza5Vt1DC7LpiKcKjczQo57VJL8Meta } from "/code/app/pages/search/analyses.vue?macro=true";
import { default as datasets_45F2w6KDCCOoOEoTOLW7vALBDoKR7mKJn_oC_45DEePTmEMeta } from "/code/app/pages/search/datasets.vue?macro=true";
import { default as specimens8neL7tUAgccbRkvilXHCnb_45J3Ifc7l78Tv_45caYLLfYgMeta } from "/code/app/pages/search/specimens.vue?macro=true";
import { default as drillcoresqkBrJEbtxglFxkXxfJ2dicMKioP0RB4_SbiDKPwHicwMeta } from "/code/app/pages/search/drillcores.vue?macro=true";
import { default as localities4QkXUxZmYDU1yhzrXqTwapfYc4Yr_IGIfJvNFP7ZIBUMeta } from "/code/app/pages/search/localities.vue?macro=true";
import { default as referencesLIgL7oXuVrFW_45rWx3aWuOC60wuS8hapayzsZsZWcXOoMeta } from "/code/app/pages/search/references.vue?macro=true";
import { default as preparationsakgoy_nkqdBm2WmR_kM8AT9gGT9fSlxHb8wPsSOWVPwMeta } from "/code/app/pages/search/preparations.vue?macro=true";
import { default as stratigraphybNEVJd9637RDnfNdqkWrcWOOXAiC08pyQ52uSIXnA5sMeta } from "/code/app/pages/search/stratigraphy.vue?macro=true";
import { default as searchNPvOKuzaX_lg1YkzID1btdilf_QL3jVkW_45otfaQu9C4Meta } from "/code/app/pages/search.vue?macro=true";
import { default as indexfwnNNoUPn0AByVWHoAY2jo3ghum6P1th8rSZxnpOGwMMeta } from "/code/app/pages/area/[id]/index.vue?macro=true";
import { default as siteshjp5O1IaPa6J_45MQh__LobBSs1DQ0goO07tXFlwdLSswMeta } from "/code/app/pages/area/[id]/sites.vue?macro=true";
import { default as localities_ByOjK_12MS7EdLjXUMY5O3Vr8BbaWva1LrW_nLBiYwMeta } from "/code/app/pages/area/[id]/localities.vue?macro=true";
import { default as referencesyvqqmBRMAiLt8j8JJfzS86aR1caNCpsadkhN9ab_45kgQMeta } from "/code/app/pages/area/[id]/references.vue?macro=true";
import { default as related_45areasp3ij_451Pyj6thw_4501OIGE6vMi537oyCp38g4hSGDzhh8Meta } from "/code/app/pages/area/[id]/related-areas.vue?macro=true";
import { default as _91id_93uq0Hgz8CUFWCX88jih62gXTBEo4_fMwh3oLUf6pK14IMeta } from "/code/app/pages/area/[id].vue?macro=true";
import { default as index4NaX5JoqN629cGAEfstoWc0B4vnBbfxzjc_oyezxz08Meta } from "/code/app/pages/file/[id]/index.vue?macro=true";
import { default as relationshsnbJu48aJwI_45cKjV2Y_450FUmEX8qeUdsSHB9TgXAYJQMeta } from "/code/app/pages/file/[id]/relations.vue?macro=true";
import { default as _91id_9346YucuMyqHurNrE8pnX__ajaTjLpHKswPuGW6WnY7KMMeta } from "/code/app/pages/file/[id].vue?macro=true";
import { default as _91id_933L3RhsV69IeCLjx5w7v11fHPNSLoaSK7oVmx6NZ1qfQMeta } from "/code/app/pages/news/[id].vue?macro=true";
import { default as index4Ato5_I_45E8ymdD8YHBSxiuWPKeSFgKXx43SBlKXVo08Meta } from "/code/app/pages/site/[id]/index.vue?macro=true";
import { default as samplesV8_45YgTAXik_456uqRcN6bbNn1Er2lVkrExiAsJ5k1zgGsMeta } from "/code/app/pages/site/[id]/samples.vue?macro=true";
import { default as referencesNGNKCx8_45JYRL8kHh41bAH_caWCDgkMl_87ZLc7qkseIMeta } from "/code/app/pages/site/[id]/references.vue?macro=true";
import { default as attachmentspY9ovYzWF8VC9F4iLbf8m2eqqzmoSqo_mFEh4ejojUwMeta } from "/code/app/pages/site/[id]/attachments.vue?macro=true";
import { default as descriptionsq4_BKkcTDidHhOJc6J3kz3Ydm8JiWl3VP2dVIslJBrYMeta } from "/code/app/pages/site/[id]/descriptions.vue?macro=true";
import { default as _91id_93pQ9u_AFLhnDt_uH06Sln_454niAD290SvbXWujYBU1RugMeta } from "/code/app/pages/site/[id].vue?macro=true";
import { default as indexpXda7xWGp2miJn2tEy5z_455bI3ywbPpmUrBU2B0YzB30Meta } from "/code/app/pages/area/index.vue?macro=true";
import { default as indexe_Hx1EhdjUgd0Gbgq0pdcTmnSqTgKorj4KYV9xR_Pt4Meta } from "/code/app/pages/news/index.vue?macro=true";
import { default as indexzIlMDv4aQf9M4afaQnBAuKU7vyfwg2EZenQSzZD1BssMeta } from "/code/app/pages/site/index.vue?macro=true";
import { default as indexKTopoL25RCK0e8Bka6GUQl8ecV9yF9aPGOTNPDOU9bYMeta } from "/code/app/pages/photo/index.vue?macro=true";
import { default as taxaYs6cO_SryeNypalQ_yHwS9ZbYsFY3aT7fBDDc_45DJG4oMeta } from "/code/app/pages/sample/[id]/taxa.vue?macro=true";
import { default as indexuvUm_451t3IcKcSi5z5SgFWutmxnzwEiUqR18fUj01eTEMeta } from "/code/app/pages/sample/[id]/index.vue?macro=true";
import { default as graphsUUkb_45ogF6QroZmv0XghE540uk05ng675xCc1Mf3HpZEMeta } from "/code/app/pages/sample/[id]/graphs.vue?macro=true";
import { default as analyses1GKNQ2uhCzYdjNXNeAxZ0XEh0RTcuF0KhhzmLAg1cBEMeta } from "/code/app/pages/sample/[id]/analyses.vue?macro=true";
import { default as referenceskcecz4IF6VpKkY8LtOaKZFwyB7UkyeNTIK_45WMg7cAf0Meta } from "/code/app/pages/sample/[id]/references.vue?macro=true";
import { default as attachments5yIz2CCxQ_45JM02FH5sNiARp1IRjkPBt4UY_zNAxQmokMeta } from "/code/app/pages/sample/[id]/attachments.vue?macro=true";
import { default as preparationsXaPcdVgR9cl028_kfry_1xFslJe3doDruoJ7dsoqfiwMeta } from "/code/app/pages/sample/[id]/preparations.vue?macro=true";
import { default as analysis_45results8SZ_PzdBfAo3F__72mob6VCGlQWND17yRDLzZdt5wCcMeta } from "/code/app/pages/sample/[id]/analysis-results.vue?macro=true";
import { default as _91id_93Rs_5np_UYqx3k1rro_45JQhGcTDitYpSDEuSLYEuLGyuQMeta } from "/code/app/pages/sample/[id].vue?macro=true";
import { default as index6AQ4_457O7RaTlFyw29F1wmZF5I2B7GThqDrvm9Ccw0fsMeta } from "/code/app/pages/taxon/index.vue?macro=true";
import { default as indexAbOQ3YpzKbWCrxT3WSOmZu6_45ibF3tb3X6Yo8HrwfJtwMeta } from "/code/app/pages/dataset/[id]/index.vue?macro=true";
import { default as graphshtJu_00_45vWNisGJu9NypciKT6nghDKHq5izqjVclW1wMeta } from "/code/app/pages/dataset/[id]/graphs.vue?macro=true";
import { default as authors2fvgQzXuS8WMXSJb17AKJ47muVv932i2_0VbZ7NFXeAMeta } from "/code/app/pages/dataset/[id]/authors.vue?macro=true";
import { default as samplesEPM2SFQFCUJquz__wWlXnMAw9O4Sbd84KI8_453ujB9FcMeta } from "/code/app/pages/dataset/[id]/samples.vue?macro=true";
import { default as analysesqNvBaiqXrWqBwdKy9pWQry4IU6ggaMCA_QMb_KhNHm0Meta } from "/code/app/pages/dataset/[id]/analyses.vue?macro=true";
import { default as referencesB_45Wh4nBAL8FCc98rrOLma8zTQ_45r4OVgQfu3iMLVx8ogMeta } from "/code/app/pages/dataset/[id]/references.vue?macro=true";
import { default as attachmentseUmNd8APPiqjE93_ln7YN4qG2nwTfrvmfga4AN8qEUsMeta } from "/code/app/pages/dataset/[id]/attachments.vue?macro=true";
import { default as geolocationsXbamO9CVq7uSR0Jl9tlOAyRy_45NuWwylNxMD_45Hc16nQ0Meta } from "/code/app/pages/dataset/[id]/geolocations.vue?macro=true";
import { default as _91id_93R0f1aGV5u9gKKNoI6TitUiTMfj_JpPvtw7FU1qEJBm8Meta } from "/code/app/pages/dataset/[id].vue?macro=true";
import { default as indexzk8xJ90bvqsu_Xqu3EeypuwTU1eatPR6wZa1zcydFcIMeta } from "/code/app/pages/sample/index.vue?macro=true";
import { default as indexEDMUr4hw2bNUPRm_45pQ29b209jQdcmsf0uTJtrLVJQXMMeta } from "/code/app/pages/analysis/[id]/index.vue?macro=true";
import { default as resultsaxyLloLE9R5KZ_45VU6_45EQ9H71ygAc_KsNF4sGKOOPegwMeta } from "/code/app/pages/analysis/[id]/results.vue?macro=true";
import { default as attachmentsuE_jwCb79SEiHal7QMnfZjh3is3nVdO8mtEOadlSPiwMeta } from "/code/app/pages/analysis/[id]/attachments.vue?macro=true";
import { default as _91id_935VT0XzCGlyWfOqeSVsR_smgJKmL1dhP6k4Nu_fQWGgYMeta } from "/code/app/pages/analysis/[id].vue?macro=true";
import { default as index_Az5o4gDii7kk3Z4_iUGQt2P7F02Kqt3_o3jpaz_3NIMeta } from "/code/app/pages/dataset/index.vue?macro=true";
import { default as indexmNyaq2lb6g1W7KfGzCF8s42GRRWpPAS5Oqm3qW6LwGcMeta } from "/code/app/pages/locality/[id]/index.vue?macro=true";
import { default as graphsGWkuPCJRAhNrHC3x4WKMM4JBbl62YHTpX4GcUd0ZUvQMeta } from "/code/app/pages/locality/[id]/graphs.vue?macro=true";
import { default as fossilsDY_sWKQhOcP8_W0_45uo1NOTubUo3TIzjfh_FyHBl2KIgMeta } from "/code/app/pages/locality/[id]/fossils.vue?macro=true";
import { default as samples2wxA3ZEVrxZANCmgCWJHUke_PHGNixx39atbSAfcsYIMeta } from "/code/app/pages/locality/[id]/samples.vue?macro=true";
import { default as analysesZVZi4dlmx8umlzecJlKhQT_45cRUJoT3ZPAuy8PcjzwFYMeta } from "/code/app/pages/locality/[id]/analyses.vue?macro=true";
import { default as synonymsEh8BnJQtfxdpmULGOW_45UcbgpHtZyWCOqd3Q0BNVSYqQMeta } from "/code/app/pages/locality/[id]/synonyms.vue?macro=true";
import { default as specimensloKq1_45EEjrlx5QM8uM7psLObuKIUhc50RI_454jTzZKfMMeta } from "/code/app/pages/locality/[id]/specimens.vue?macro=true";
import { default as referencesg3AUTDNPDDu6ACLdXj_p9tafhpUJtrBhxaTLsxPdCnkMeta } from "/code/app/pages/locality/[id]/references.vue?macro=true";
import { default as attachmentsW2YtP4uJ6hMd390fIXDoCy2XKawyJQzr_458egEODN6zAMeta } from "/code/app/pages/locality/[id]/attachments.vue?macro=true";
import { default as stratotypesQ4jQhhbhgpzNu5vyJZqxY_45cOnlyYp0_45yZInAHG_45rTqsMeta } from "/code/app/pages/locality/[id]/stratotypes.vue?macro=true";
import { default as descriptionskDc1ZNEpngr8C_45tad2Y82yo0453kAAAVLo_45QzkcIN_sMeta } from "/code/app/pages/locality/[id]/descriptions.vue?macro=true";
import { default as drillcore_45boxesDQGfXJlCpYajGmzotAGk26AZWViiLTG_zmJbs2TpXMQMeta } from "/code/app/pages/locality/[id]/drillcore-boxes.vue?macro=true";
import { default as _91id_931HA7tbjdgmwZOXlMsOBBohLabdbP9gOi35P4AhL5Cj4Meta } from "/code/app/pages/locality/[id].vue?macro=true";
import { default as index6HTPCC2NxKclY7GIQZSypeRnLgpUpQva_C0NFS_wVR4Meta } from "/code/app/pages/specimen/[id]/index.vue?macro=true";
import { default as referencesgFwGIsCvCXfKEeV_4550b52YkLKWSvTAluy7tkLDp_35AMeta } from "/code/app/pages/specimen/[id]/references.vue?macro=true";
import { default as identificationsW0p0Wi_K9Bw5D6wZq4r1WFI25d_45Ju3726i6SDdBspPoMeta } from "/code/app/pages/specimen/[id]/identifications.vue?macro=true";
import { default as rock_45identificationshBXX0TxT8cBSLiMx0GgxiuG_45sEUW_wqge0W4SIHY0_45wMeta } from "/code/app/pages/specimen/[id]/rock-identifications.vue?macro=true";
import { default as _91id_937WmA7XzOdQOh54icqeyKbEnx63ZrJ7L_pZIzQobUeRIMeta } from "/code/app/pages/specimen/[id].vue?macro=true";
import { default as indexwZMgm9G4z_45LX_45mJV24tLXADvtoGrxvgg4krHerYpzIIMeta } from "/code/app/pages/analysis/index.vue?macro=true";
import { default as boxes8yz_03o2tqj2US2jihGAvNA8GMv_iHhpFDwFmlmrKaYMeta } from "/code/app/pages/drillcore/[id]/boxes.vue?macro=true";
import { default as indexfLOlD3cDjMFv_45SYJ0B1_xVck3s1GlMgqCA7iy62q9HUMeta } from "/code/app/pages/drillcore/[id]/index.vue?macro=true";
import { default as graphsIeLo6T9tsGEDqEZ6d_ADyToUsFSQbohUlajXdj4BOQIMeta } from "/code/app/pages/drillcore/[id]/graphs.vue?macro=true";
import { default as samplesDAD_45H6OtqyZ_jwYDLecDunAquwyGkMLs9Ag5ldOtIaQMeta } from "/code/app/pages/drillcore/[id]/samples.vue?macro=true";
import { default as analysesK1BsQAqtQyoINlLSYunl8665JU4rOLAiYUXugQ_iamgMeta } from "/code/app/pages/drillcore/[id]/analyses.vue?macro=true";
import { default as specimenspPJvOutA435fHc1PLr8y1Hj5eUIp_5WjQhqMgoy7q2sMeta } from "/code/app/pages/drillcore/[id]/specimens.vue?macro=true";
import { default as referencesIL_MsB75qeSQkS8La8kduifwod3JQzQp7Z0VblEdeIkMeta } from "/code/app/pages/drillcore/[id]/references.vue?macro=true";
import { default as attachmentsOTxqkiduAwvl_d5X7M3_trNLckNzDLTDl0EOcAulx5oMeta } from "/code/app/pages/drillcore/[id]/attachments.vue?macro=true";
import { default as descriptionsGtOd4Uc6qn6rUoob57BCjp_747kU6Q05Ihs9UK1oLP8Meta } from "/code/app/pages/drillcore/[id]/descriptions.vue?macro=true";
import { default as _91id_93jQZ51Y7paywf2xy0RuwGqw6kxx937fr3eMdFkk9VjIQMeta } from "/code/app/pages/drillcore/[id].vue?macro=true";
import { default as indexAAYNr4JxYAKl0w9t9bOID34Vb_TB8l2gYxqzVgvgpcQMeta } from "/code/app/pages/locality/index.vue?macro=true";
import { default as indexd3YtlXwEERI8NkWHk290j9_45q9N_45_45U3umBi7ls7Rig9gMeta } from "/code/app/pages/specimen/index.vue?macro=true";
import { default as indexgbAYfZixOCHZAMP0Dhe__45XX_l_2F1AM4I3O4IYkD5g8Meta } from "/code/app/pages/collection/[id]/index.vue?macro=true";
import { default as specimensXqPvCkw9DsDyLAYVczm7kdk8DKCkYtk_ig2HXXVE_kEMeta } from "/code/app/pages/collection/[id]/specimens.vue?macro=true";
import { default as _91id_93ciZqBjgpC9LSbQ2Rpua_45ofZKyFRc216XyfH4joirooYMeta } from "/code/app/pages/collection/[id].vue?macro=true";
import { default as index_45nb6sjP8W4KurpfK3y9mmdSTKsq8cv9rQGSTTmCHR8UMeta } from "/code/app/pages/drillcore/index.vue?macro=true";
import { default as egkaO1fNTgqZGXADeGL4io5XQ_4545YkjouL3gySSRqWCgt8Meta } from "/code/app/pages/institution/egk.vue?macro=true";
import { default as egtfxUZt9S5piW84khgnb15gDSeA9HLM2N5zA4VlZCOO5gMeta } from "/code/app/pages/institution/egt.vue?macro=true";
import { default as elmqylETte4XOjZHMBejw9V4zpTa5vvYhDIB_OXghyREXEMeta } from "/code/app/pages/institution/elm.vue?macro=true";
import { default as gitfW6SPpGpmZW1B4Gy9Wj_0n_45job5684_Mlde7I_FiAZEMeta } from "/code/app/pages/institution/git.vue?macro=true";
import { default as tugp42GyvddiaqikpibKwx1W7VZMdRX382T1KNsrABdL1sMeta } from "/code/app/pages/institution/tug.vue?macro=true";
import { default as mumui_457TQ0GGsQ2oWqvHVn7pPwxO6Gm7a8t3YR5rEoO_45qsIMeta } from "/code/app/pages/institution/mumu.vue?macro=true";
import { default as tugoJXbWSUAYFunYhRwl4Vg3_rThG_45LCCjc8mYU81ou2BxwMeta } from "/code/app/pages/institution/tugo.vue?macro=true";
import { default as taxasuqXFHZGpORq_qHMg0Z7I_45H_45IvAF3CSWSAm0gYJbVy0Meta } from "/code/app/pages/preparation/[id]/taxa.vue?macro=true";
import { default as indexnhHqUY4umGxba2VTHgckfL40i4CgSl4dGwi0oMpXDFEMeta } from "/code/app/pages/preparation/[id]/index.vue?macro=true";
import { default as attachmentsf5_45o208B7wMNH2sz_45_45JWnOvYBM9_45GERv4TVI7_7umkUMeta } from "/code/app/pages/preparation/[id]/attachments.vue?macro=true";
import { default as _91id_935zV1u7TwHmMwDJM10NawdpH523nycfKHAOldHEze8SIMeta } from "/code/app/pages/preparation/[id].vue?macro=true";
import { default as indexTXiuyRyf3zsltQymw2wv9wbJSEmtTBvxjjHjTFYtPxUMeta } from "/code/app/pages/preparation/index.vue?macro=true";
import { default as indexfleuCbWZhtmNHSik8WkQWhBYQUn4zIDPXJ9yaSfZpCQMeta } from "/code/app/pages/stratigraphy/[id]/index.vue?macro=true";
import { default as samplesdnVGuP1ZkMbjwEIPZ8w_45D4uuLMCJOUCp891T3B5nLxgMeta } from "/code/app/pages/stratigraphy/[id]/samples.vue?macro=true";
import { default as subunitsbJtefsiksEjnrcbKh9dTUC7HeqhEIbuidPboO4JawvAMeta } from "/code/app/pages/stratigraphy/[id]/subunits.vue?macro=true";
import { default as synonymsqrqsvv9cuOdMtw3YsxRPKYmQzPL1C4CuT3c6XZGBUvIMeta } from "/code/app/pages/stratigraphy/[id]/synonyms.vue?macro=true";
import { default as specimenssHUqSI_45lqQCdoAE7Ow96svfnhu8ONWza_RQRMsqhdcwMeta } from "/code/app/pages/stratigraphy/[id]/specimens.vue?macro=true";
import { default as referencesF7KKKygyEcuMzla7zm8muJHsg4P3IAGoxHvnHZyuqgIMeta } from "/code/app/pages/stratigraphy/[id]/references.vue?macro=true";
import { default as related_45unitsOiMvtCpj1XAHZc7tzCMTf9LUteogDnn_45iegLtF_qBvsMeta } from "/code/app/pages/stratigraphy/[id]/related-units.vue?macro=true";
import { default as _91id_93B8emRGXIsJW1Fq_45VbTcxDm3rJ30Yai_9ELOkAtGhrVEMeta } from "/code/app/pages/stratigraphy/[id].vue?macro=true";
import { default as indexTFMvnsjXj_Yq0foUDq37Limhcz6RJuOPsbtWb_45A1hPsMeta } from "/code/app/pages/drillcore-box/[id]/index.vue?macro=true";
import { default as sampleskz3ZnhrO4k22MaszlBQs92hZD4vWuyODtKNas35eAFQMeta } from "/code/app/pages/drillcore-box/[id]/samples.vue?macro=true";
import { default as analysesPdRlIc4AlGUo0cb0NI8I3_P660PmajWeRODwyGn8H7EMeta } from "/code/app/pages/drillcore-box/[id]/analyses.vue?macro=true";
import { default as specimensQ7GmfE_45s2QEHeLufiJolLyS3y5OeOHkYqXRWOwz39fEMeta } from "/code/app/pages/drillcore-box/[id]/specimens.vue?macro=true";
import { default as _91id_93HRl2tOM5nO9H2y6CMDaJZ7rrweqKgN8syTM_03nVlbwMeta } from "/code/app/pages/drillcore-box/[id].vue?macro=true";
import { default as indexdVgpS5QZKCyeIHx17Uq55J_g0BvdfYaRSwWPwqaJ3KYMeta } from "/code/app/pages/stratigraphy/index.vue?macro=true";
import { default as indexjAjpZVeukC38nPG1fd7tqnqpfohDRObM5rf4IDYFySMMeta } from "/code/app/pages/analytical-data/index.vue?macro=true";
import { default as component_45stub2RK8NI9EPuQlDWCpb2ZVFhMfEk0tUJyI05LzPSN8YDUMeta } from "/code/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub2RK8NI9EPuQlDWCpb2ZVFhMfEk0tUJyI05LzPSN8YDU } from "/code/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/code/app/pages/about.vue")
  },
  {
    name: "about___et___default",
    path: "/about",
    component: () => import("/code/app/pages/about.vue")
  },
  {
    name: "about___et",
    path: "/et/about",
    component: () => import("/code/app/pages/about.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexnGMQ_45igP6cws8hWcw5uGdHZr9Kemt0P7rMbYdr_P9ccMeta || {},
    component: () => import("/code/app/pages/index.vue")
  },
  {
    name: "index___et___default",
    path: "/",
    meta: indexnGMQ_45igP6cws8hWcw5uGdHZr9Kemt0P7rMbYdr_P9ccMeta || {},
    component: () => import("/code/app/pages/index.vue")
  },
  {
    name: "index___et",
    path: "/et",
    meta: indexnGMQ_45igP6cws8hWcw5uGdHZr9Kemt0P7rMbYdr_P9ccMeta || {},
    component: () => import("/code/app/pages/index.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/code/app/pages/search.vue"),
    children: [
  {
    name: "search-dois___en",
    path: "dois",
    component: () => import("/code/app/pages/search/dois.vue")
  },
  {
    name: "search-taxa___en",
    path: "taxa",
    component: () => import("/code/app/pages/search/taxa.vue")
  },
  {
    name: "search-areas___en",
    path: "areas",
    component: () => import("/code/app/pages/search/areas.vue")
  },
  {
    name: "search-files___en",
    path: "files",
    component: () => import("/code/app/pages/search/files.vue")
  },
  {
    name: "search-rocks___en",
    path: "rocks",
    component: () => import("/code/app/pages/search/rocks.vue")
  },
  {
    name: "search-sites___en",
    path: "sites",
    component: () => import("/code/app/pages/search/sites.vue")
  },
  {
    name: "search-photos___en",
    path: "photos",
    component: () => import("/code/app/pages/search/photos.vue")
  },
  {
    name: "search-samples___en",
    path: "samples",
    component: () => import("/code/app/pages/search/samples.vue")
  },
  {
    name: "search-analyses___en",
    path: "analyses",
    component: () => import("/code/app/pages/search/analyses.vue")
  },
  {
    name: "search-datasets___en",
    path: "datasets",
    component: () => import("/code/app/pages/search/datasets.vue")
  },
  {
    name: "search-specimens___en",
    path: "specimens",
    component: () => import("/code/app/pages/search/specimens.vue")
  },
  {
    name: "search-drillcores___en",
    path: "drillcores",
    component: () => import("/code/app/pages/search/drillcores.vue")
  },
  {
    name: "search-localities___en",
    path: "localities",
    component: () => import("/code/app/pages/search/localities.vue")
  },
  {
    name: "search-references___en",
    path: "references",
    component: () => import("/code/app/pages/search/references.vue")
  },
  {
    name: "search-preparations___en",
    path: "preparations",
    component: () => import("/code/app/pages/search/preparations.vue")
  },
  {
    name: "search-stratigraphy___en",
    path: "stratigraphy",
    component: () => import("/code/app/pages/search/stratigraphy.vue")
  }
]
  },
  {
    name: "search___et___default",
    path: "/search",
    component: () => import("/code/app/pages/search.vue"),
    children: [
  {
    name: "search-dois___et___default",
    path: "dois",
    component: () => import("/code/app/pages/search/dois.vue")
  },
  {
    name: "search-taxa___et___default",
    path: "taxa",
    component: () => import("/code/app/pages/search/taxa.vue")
  },
  {
    name: "search-areas___et___default",
    path: "areas",
    component: () => import("/code/app/pages/search/areas.vue")
  },
  {
    name: "search-files___et___default",
    path: "files",
    component: () => import("/code/app/pages/search/files.vue")
  },
  {
    name: "search-rocks___et___default",
    path: "rocks",
    component: () => import("/code/app/pages/search/rocks.vue")
  },
  {
    name: "search-sites___et___default",
    path: "sites",
    component: () => import("/code/app/pages/search/sites.vue")
  },
  {
    name: "search-photos___et___default",
    path: "photos",
    component: () => import("/code/app/pages/search/photos.vue")
  },
  {
    name: "search-samples___et___default",
    path: "samples",
    component: () => import("/code/app/pages/search/samples.vue")
  },
  {
    name: "search-analyses___et___default",
    path: "analyses",
    component: () => import("/code/app/pages/search/analyses.vue")
  },
  {
    name: "search-datasets___et___default",
    path: "datasets",
    component: () => import("/code/app/pages/search/datasets.vue")
  },
  {
    name: "search-specimens___et___default",
    path: "specimens",
    component: () => import("/code/app/pages/search/specimens.vue")
  },
  {
    name: "search-drillcores___et___default",
    path: "drillcores",
    component: () => import("/code/app/pages/search/drillcores.vue")
  },
  {
    name: "search-localities___et___default",
    path: "localities",
    component: () => import("/code/app/pages/search/localities.vue")
  },
  {
    name: "search-references___et___default",
    path: "references",
    component: () => import("/code/app/pages/search/references.vue")
  },
  {
    name: "search-preparations___et___default",
    path: "preparations",
    component: () => import("/code/app/pages/search/preparations.vue")
  },
  {
    name: "search-stratigraphy___et___default",
    path: "stratigraphy",
    component: () => import("/code/app/pages/search/stratigraphy.vue")
  }
]
  },
  {
    name: "search___et",
    path: "/et/search",
    component: () => import("/code/app/pages/search.vue"),
    children: [
  {
    name: "search-dois___et",
    path: "dois",
    component: () => import("/code/app/pages/search/dois.vue")
  },
  {
    name: "search-taxa___et",
    path: "taxa",
    component: () => import("/code/app/pages/search/taxa.vue")
  },
  {
    name: "search-areas___et",
    path: "areas",
    component: () => import("/code/app/pages/search/areas.vue")
  },
  {
    name: "search-files___et",
    path: "files",
    component: () => import("/code/app/pages/search/files.vue")
  },
  {
    name: "search-rocks___et",
    path: "rocks",
    component: () => import("/code/app/pages/search/rocks.vue")
  },
  {
    name: "search-sites___et",
    path: "sites",
    component: () => import("/code/app/pages/search/sites.vue")
  },
  {
    name: "search-photos___et",
    path: "photos",
    component: () => import("/code/app/pages/search/photos.vue")
  },
  {
    name: "search-samples___et",
    path: "samples",
    component: () => import("/code/app/pages/search/samples.vue")
  },
  {
    name: "search-analyses___et",
    path: "analyses",
    component: () => import("/code/app/pages/search/analyses.vue")
  },
  {
    name: "search-datasets___et",
    path: "datasets",
    component: () => import("/code/app/pages/search/datasets.vue")
  },
  {
    name: "search-specimens___et",
    path: "specimens",
    component: () => import("/code/app/pages/search/specimens.vue")
  },
  {
    name: "search-drillcores___et",
    path: "drillcores",
    component: () => import("/code/app/pages/search/drillcores.vue")
  },
  {
    name: "search-localities___et",
    path: "localities",
    component: () => import("/code/app/pages/search/localities.vue")
  },
  {
    name: "search-references___et",
    path: "references",
    component: () => import("/code/app/pages/search/references.vue")
  },
  {
    name: "search-preparations___et",
    path: "preparations",
    component: () => import("/code/app/pages/search/preparations.vue")
  },
  {
    name: "search-stratigraphy___et",
    path: "stratigraphy",
    component: () => import("/code/app/pages/search/stratigraphy.vue")
  }
]
  },
  {
    name: _91id_93uq0Hgz8CUFWCX88jih62gXTBEo4_fMwh3oLUf6pK14IMeta?.name,
    path: "/en/area/:id()",
    component: () => import("/code/app/pages/area/[id].vue"),
    children: [
  {
    name: "area-id___en",
    path: "",
    component: () => import("/code/app/pages/area/[id]/index.vue")
  },
  {
    name: "area-id-sites___en",
    path: "sites",
    component: () => import("/code/app/pages/area/[id]/sites.vue")
  },
  {
    name: "area-id-localities___en",
    path: "localities",
    component: () => import("/code/app/pages/area/[id]/localities.vue")
  },
  {
    name: "area-id-references___en",
    path: "references",
    component: () => import("/code/app/pages/area/[id]/references.vue")
  },
  {
    name: "area-id-related-areas___en",
    path: "related-areas",
    component: () => import("/code/app/pages/area/[id]/related-areas.vue")
  }
]
  },
  {
    name: _91id_93uq0Hgz8CUFWCX88jih62gXTBEo4_fMwh3oLUf6pK14IMeta?.name,
    path: "/area/:id()",
    component: () => import("/code/app/pages/area/[id].vue"),
    children: [
  {
    name: "area-id___et___default",
    path: "",
    component: () => import("/code/app/pages/area/[id]/index.vue")
  },
  {
    name: "area-id-sites___et___default",
    path: "sites",
    component: () => import("/code/app/pages/area/[id]/sites.vue")
  },
  {
    name: "area-id-localities___et___default",
    path: "localities",
    component: () => import("/code/app/pages/area/[id]/localities.vue")
  },
  {
    name: "area-id-references___et___default",
    path: "references",
    component: () => import("/code/app/pages/area/[id]/references.vue")
  },
  {
    name: "area-id-related-areas___et___default",
    path: "related-areas",
    component: () => import("/code/app/pages/area/[id]/related-areas.vue")
  }
]
  },
  {
    name: _91id_93uq0Hgz8CUFWCX88jih62gXTBEo4_fMwh3oLUf6pK14IMeta?.name,
    path: "/et/area/:id()",
    component: () => import("/code/app/pages/area/[id].vue"),
    children: [
  {
    name: "area-id___et",
    path: "",
    component: () => import("/code/app/pages/area/[id]/index.vue")
  },
  {
    name: "area-id-sites___et",
    path: "sites",
    component: () => import("/code/app/pages/area/[id]/sites.vue")
  },
  {
    name: "area-id-localities___et",
    path: "localities",
    component: () => import("/code/app/pages/area/[id]/localities.vue")
  },
  {
    name: "area-id-references___et",
    path: "references",
    component: () => import("/code/app/pages/area/[id]/references.vue")
  },
  {
    name: "area-id-related-areas___et",
    path: "related-areas",
    component: () => import("/code/app/pages/area/[id]/related-areas.vue")
  }
]
  },
  {
    name: _91id_9346YucuMyqHurNrE8pnX__ajaTjLpHKswPuGW6WnY7KMMeta?.name,
    path: "/en/file/:id()",
    component: () => import("/code/app/pages/file/[id].vue"),
    children: [
  {
    name: "file-id___en",
    path: "",
    component: () => import("/code/app/pages/file/[id]/index.vue")
  },
  {
    name: "file-id-relations___en",
    path: "relations",
    component: () => import("/code/app/pages/file/[id]/relations.vue")
  }
]
  },
  {
    name: _91id_9346YucuMyqHurNrE8pnX__ajaTjLpHKswPuGW6WnY7KMMeta?.name,
    path: "/file/:id()",
    component: () => import("/code/app/pages/file/[id].vue"),
    children: [
  {
    name: "file-id___et___default",
    path: "",
    component: () => import("/code/app/pages/file/[id]/index.vue")
  },
  {
    name: "file-id-relations___et___default",
    path: "relations",
    component: () => import("/code/app/pages/file/[id]/relations.vue")
  }
]
  },
  {
    name: _91id_9346YucuMyqHurNrE8pnX__ajaTjLpHKswPuGW6WnY7KMMeta?.name,
    path: "/et/file/:id()",
    component: () => import("/code/app/pages/file/[id].vue"),
    children: [
  {
    name: "file-id___et",
    path: "",
    component: () => import("/code/app/pages/file/[id]/index.vue")
  },
  {
    name: "file-id-relations___et",
    path: "relations",
    component: () => import("/code/app/pages/file/[id]/relations.vue")
  }
]
  },
  {
    name: "news-id___en",
    path: "/en/news/:id()",
    component: () => import("/code/app/pages/news/[id].vue")
  },
  {
    name: "news-id___et___default",
    path: "/news/:id()",
    component: () => import("/code/app/pages/news/[id].vue")
  },
  {
    name: "news-id___et",
    path: "/et/news/:id()",
    component: () => import("/code/app/pages/news/[id].vue")
  },
  {
    name: _91id_93pQ9u_AFLhnDt_uH06Sln_454niAD290SvbXWujYBU1RugMeta?.name,
    path: "/en/site/:id()",
    component: () => import("/code/app/pages/site/[id].vue"),
    children: [
  {
    name: "site-id___en",
    path: "",
    component: () => import("/code/app/pages/site/[id]/index.vue")
  },
  {
    name: "site-id-samples___en",
    path: "samples",
    component: () => import("/code/app/pages/site/[id]/samples.vue")
  },
  {
    name: "site-id-references___en",
    path: "references",
    component: () => import("/code/app/pages/site/[id]/references.vue")
  },
  {
    name: "site-id-attachments___en",
    path: "attachments",
    component: () => import("/code/app/pages/site/[id]/attachments.vue")
  },
  {
    name: "site-id-descriptions___en",
    path: "descriptions",
    component: () => import("/code/app/pages/site/[id]/descriptions.vue")
  }
]
  },
  {
    name: _91id_93pQ9u_AFLhnDt_uH06Sln_454niAD290SvbXWujYBU1RugMeta?.name,
    path: "/site/:id()",
    component: () => import("/code/app/pages/site/[id].vue"),
    children: [
  {
    name: "site-id___et___default",
    path: "",
    component: () => import("/code/app/pages/site/[id]/index.vue")
  },
  {
    name: "site-id-samples___et___default",
    path: "samples",
    component: () => import("/code/app/pages/site/[id]/samples.vue")
  },
  {
    name: "site-id-references___et___default",
    path: "references",
    component: () => import("/code/app/pages/site/[id]/references.vue")
  },
  {
    name: "site-id-attachments___et___default",
    path: "attachments",
    component: () => import("/code/app/pages/site/[id]/attachments.vue")
  },
  {
    name: "site-id-descriptions___et___default",
    path: "descriptions",
    component: () => import("/code/app/pages/site/[id]/descriptions.vue")
  }
]
  },
  {
    name: _91id_93pQ9u_AFLhnDt_uH06Sln_454niAD290SvbXWujYBU1RugMeta?.name,
    path: "/et/site/:id()",
    component: () => import("/code/app/pages/site/[id].vue"),
    children: [
  {
    name: "site-id___et",
    path: "",
    component: () => import("/code/app/pages/site/[id]/index.vue")
  },
  {
    name: "site-id-samples___et",
    path: "samples",
    component: () => import("/code/app/pages/site/[id]/samples.vue")
  },
  {
    name: "site-id-references___et",
    path: "references",
    component: () => import("/code/app/pages/site/[id]/references.vue")
  },
  {
    name: "site-id-attachments___et",
    path: "attachments",
    component: () => import("/code/app/pages/site/[id]/attachments.vue")
  },
  {
    name: "site-id-descriptions___et",
    path: "descriptions",
    component: () => import("/code/app/pages/site/[id]/descriptions.vue")
  }
]
  },
  {
    name: "area___en",
    path: "/en/area",
    component: () => import("/code/app/pages/area/index.vue")
  },
  {
    name: "area___et___default",
    path: "/area",
    component: () => import("/code/app/pages/area/index.vue")
  },
  {
    name: "area___et",
    path: "/et/area",
    component: () => import("/code/app/pages/area/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/code/app/pages/news/index.vue")
  },
  {
    name: "news___et___default",
    path: "/news",
    component: () => import("/code/app/pages/news/index.vue")
  },
  {
    name: "news___et",
    path: "/et/news",
    component: () => import("/code/app/pages/news/index.vue")
  },
  {
    name: "site___en",
    path: "/en/site",
    component: () => import("/code/app/pages/site/index.vue")
  },
  {
    name: "site___et___default",
    path: "/site",
    component: () => import("/code/app/pages/site/index.vue")
  },
  {
    name: "site___et",
    path: "/et/site",
    component: () => import("/code/app/pages/site/index.vue")
  },
  {
    name: "photo___en",
    path: "/en/photo",
    component: () => import("/code/app/pages/photo/index.vue")
  },
  {
    name: "photo___et___default",
    path: "/photo",
    component: () => import("/code/app/pages/photo/index.vue")
  },
  {
    name: "photo___et",
    path: "/et/photo",
    component: () => import("/code/app/pages/photo/index.vue")
  },
  {
    name: _91id_93Rs_5np_UYqx3k1rro_45JQhGcTDitYpSDEuSLYEuLGyuQMeta?.name,
    path: "/en/sample/:id()",
    component: () => import("/code/app/pages/sample/[id].vue"),
    children: [
  {
    name: "sample-id-taxa___en",
    path: "taxa",
    component: () => import("/code/app/pages/sample/[id]/taxa.vue")
  },
  {
    name: "sample-id___en",
    path: "",
    component: () => import("/code/app/pages/sample/[id]/index.vue")
  },
  {
    name: "sample-id-graphs___en",
    path: "graphs",
    component: () => import("/code/app/pages/sample/[id]/graphs.vue")
  },
  {
    name: "sample-id-analyses___en",
    path: "analyses",
    component: () => import("/code/app/pages/sample/[id]/analyses.vue")
  },
  {
    name: "sample-id-references___en",
    path: "references",
    component: () => import("/code/app/pages/sample/[id]/references.vue")
  },
  {
    name: "sample-id-attachments___en",
    path: "attachments",
    component: () => import("/code/app/pages/sample/[id]/attachments.vue")
  },
  {
    name: "sample-id-preparations___en",
    path: "preparations",
    component: () => import("/code/app/pages/sample/[id]/preparations.vue")
  },
  {
    name: "sample-id-analysis-results___en",
    path: "analysis-results",
    component: () => import("/code/app/pages/sample/[id]/analysis-results.vue")
  }
]
  },
  {
    name: _91id_93Rs_5np_UYqx3k1rro_45JQhGcTDitYpSDEuSLYEuLGyuQMeta?.name,
    path: "/sample/:id()",
    component: () => import("/code/app/pages/sample/[id].vue"),
    children: [
  {
    name: "sample-id-taxa___et___default",
    path: "taxa",
    component: () => import("/code/app/pages/sample/[id]/taxa.vue")
  },
  {
    name: "sample-id___et___default",
    path: "",
    component: () => import("/code/app/pages/sample/[id]/index.vue")
  },
  {
    name: "sample-id-graphs___et___default",
    path: "graphs",
    component: () => import("/code/app/pages/sample/[id]/graphs.vue")
  },
  {
    name: "sample-id-analyses___et___default",
    path: "analyses",
    component: () => import("/code/app/pages/sample/[id]/analyses.vue")
  },
  {
    name: "sample-id-references___et___default",
    path: "references",
    component: () => import("/code/app/pages/sample/[id]/references.vue")
  },
  {
    name: "sample-id-attachments___et___default",
    path: "attachments",
    component: () => import("/code/app/pages/sample/[id]/attachments.vue")
  },
  {
    name: "sample-id-preparations___et___default",
    path: "preparations",
    component: () => import("/code/app/pages/sample/[id]/preparations.vue")
  },
  {
    name: "sample-id-analysis-results___et___default",
    path: "analysis-results",
    component: () => import("/code/app/pages/sample/[id]/analysis-results.vue")
  }
]
  },
  {
    name: _91id_93Rs_5np_UYqx3k1rro_45JQhGcTDitYpSDEuSLYEuLGyuQMeta?.name,
    path: "/et/sample/:id()",
    component: () => import("/code/app/pages/sample/[id].vue"),
    children: [
  {
    name: "sample-id-taxa___et",
    path: "taxa",
    component: () => import("/code/app/pages/sample/[id]/taxa.vue")
  },
  {
    name: "sample-id___et",
    path: "",
    component: () => import("/code/app/pages/sample/[id]/index.vue")
  },
  {
    name: "sample-id-graphs___et",
    path: "graphs",
    component: () => import("/code/app/pages/sample/[id]/graphs.vue")
  },
  {
    name: "sample-id-analyses___et",
    path: "analyses",
    component: () => import("/code/app/pages/sample/[id]/analyses.vue")
  },
  {
    name: "sample-id-references___et",
    path: "references",
    component: () => import("/code/app/pages/sample/[id]/references.vue")
  },
  {
    name: "sample-id-attachments___et",
    path: "attachments",
    component: () => import("/code/app/pages/sample/[id]/attachments.vue")
  },
  {
    name: "sample-id-preparations___et",
    path: "preparations",
    component: () => import("/code/app/pages/sample/[id]/preparations.vue")
  },
  {
    name: "sample-id-analysis-results___et",
    path: "analysis-results",
    component: () => import("/code/app/pages/sample/[id]/analysis-results.vue")
  }
]
  },
  {
    name: "taxon___en",
    path: "/en/taxon",
    component: () => import("/code/app/pages/taxon/index.vue")
  },
  {
    name: "taxon___et___default",
    path: "/taxon",
    component: () => import("/code/app/pages/taxon/index.vue")
  },
  {
    name: "taxon___et",
    path: "/et/taxon",
    component: () => import("/code/app/pages/taxon/index.vue")
  },
  {
    name: _91id_93R0f1aGV5u9gKKNoI6TitUiTMfj_JpPvtw7FU1qEJBm8Meta?.name,
    path: "/en/dataset/:id()",
    component: () => import("/code/app/pages/dataset/[id].vue"),
    children: [
  {
    name: "dataset-id___en",
    path: "",
    component: () => import("/code/app/pages/dataset/[id]/index.vue")
  },
  {
    name: "dataset-id-graphs___en",
    path: "graphs",
    component: () => import("/code/app/pages/dataset/[id]/graphs.vue")
  },
  {
    name: "dataset-id-authors___en",
    path: "authors",
    component: () => import("/code/app/pages/dataset/[id]/authors.vue")
  },
  {
    name: "dataset-id-samples___en",
    path: "samples",
    component: () => import("/code/app/pages/dataset/[id]/samples.vue")
  },
  {
    name: "dataset-id-analyses___en",
    path: "analyses",
    component: () => import("/code/app/pages/dataset/[id]/analyses.vue")
  },
  {
    name: "dataset-id-references___en",
    path: "references",
    component: () => import("/code/app/pages/dataset/[id]/references.vue")
  },
  {
    name: "dataset-id-attachments___en",
    path: "attachments",
    component: () => import("/code/app/pages/dataset/[id]/attachments.vue")
  },
  {
    name: "dataset-id-geolocations___en",
    path: "geolocations",
    component: () => import("/code/app/pages/dataset/[id]/geolocations.vue")
  }
]
  },
  {
    name: _91id_93R0f1aGV5u9gKKNoI6TitUiTMfj_JpPvtw7FU1qEJBm8Meta?.name,
    path: "/dataset/:id()",
    component: () => import("/code/app/pages/dataset/[id].vue"),
    children: [
  {
    name: "dataset-id___et___default",
    path: "",
    component: () => import("/code/app/pages/dataset/[id]/index.vue")
  },
  {
    name: "dataset-id-graphs___et___default",
    path: "graphs",
    component: () => import("/code/app/pages/dataset/[id]/graphs.vue")
  },
  {
    name: "dataset-id-authors___et___default",
    path: "authors",
    component: () => import("/code/app/pages/dataset/[id]/authors.vue")
  },
  {
    name: "dataset-id-samples___et___default",
    path: "samples",
    component: () => import("/code/app/pages/dataset/[id]/samples.vue")
  },
  {
    name: "dataset-id-analyses___et___default",
    path: "analyses",
    component: () => import("/code/app/pages/dataset/[id]/analyses.vue")
  },
  {
    name: "dataset-id-references___et___default",
    path: "references",
    component: () => import("/code/app/pages/dataset/[id]/references.vue")
  },
  {
    name: "dataset-id-attachments___et___default",
    path: "attachments",
    component: () => import("/code/app/pages/dataset/[id]/attachments.vue")
  },
  {
    name: "dataset-id-geolocations___et___default",
    path: "geolocations",
    component: () => import("/code/app/pages/dataset/[id]/geolocations.vue")
  }
]
  },
  {
    name: _91id_93R0f1aGV5u9gKKNoI6TitUiTMfj_JpPvtw7FU1qEJBm8Meta?.name,
    path: "/et/dataset/:id()",
    component: () => import("/code/app/pages/dataset/[id].vue"),
    children: [
  {
    name: "dataset-id___et",
    path: "",
    component: () => import("/code/app/pages/dataset/[id]/index.vue")
  },
  {
    name: "dataset-id-graphs___et",
    path: "graphs",
    component: () => import("/code/app/pages/dataset/[id]/graphs.vue")
  },
  {
    name: "dataset-id-authors___et",
    path: "authors",
    component: () => import("/code/app/pages/dataset/[id]/authors.vue")
  },
  {
    name: "dataset-id-samples___et",
    path: "samples",
    component: () => import("/code/app/pages/dataset/[id]/samples.vue")
  },
  {
    name: "dataset-id-analyses___et",
    path: "analyses",
    component: () => import("/code/app/pages/dataset/[id]/analyses.vue")
  },
  {
    name: "dataset-id-references___et",
    path: "references",
    component: () => import("/code/app/pages/dataset/[id]/references.vue")
  },
  {
    name: "dataset-id-attachments___et",
    path: "attachments",
    component: () => import("/code/app/pages/dataset/[id]/attachments.vue")
  },
  {
    name: "dataset-id-geolocations___et",
    path: "geolocations",
    component: () => import("/code/app/pages/dataset/[id]/geolocations.vue")
  }
]
  },
  {
    name: "sample___en",
    path: "/en/sample",
    component: () => import("/code/app/pages/sample/index.vue")
  },
  {
    name: "sample___et___default",
    path: "/sample",
    component: () => import("/code/app/pages/sample/index.vue")
  },
  {
    name: "sample___et",
    path: "/et/sample",
    component: () => import("/code/app/pages/sample/index.vue")
  },
  {
    name: _91id_935VT0XzCGlyWfOqeSVsR_smgJKmL1dhP6k4Nu_fQWGgYMeta?.name,
    path: "/en/analysis/:id()",
    component: () => import("/code/app/pages/analysis/[id].vue"),
    children: [
  {
    name: "analysis-id___en",
    path: "",
    component: () => import("/code/app/pages/analysis/[id]/index.vue")
  },
  {
    name: "analysis-id-results___en",
    path: "results",
    component: () => import("/code/app/pages/analysis/[id]/results.vue")
  },
  {
    name: "analysis-id-attachments___en",
    path: "attachments",
    component: () => import("/code/app/pages/analysis/[id]/attachments.vue")
  }
]
  },
  {
    name: _91id_935VT0XzCGlyWfOqeSVsR_smgJKmL1dhP6k4Nu_fQWGgYMeta?.name,
    path: "/analysis/:id()",
    component: () => import("/code/app/pages/analysis/[id].vue"),
    children: [
  {
    name: "analysis-id___et___default",
    path: "",
    component: () => import("/code/app/pages/analysis/[id]/index.vue")
  },
  {
    name: "analysis-id-results___et___default",
    path: "results",
    component: () => import("/code/app/pages/analysis/[id]/results.vue")
  },
  {
    name: "analysis-id-attachments___et___default",
    path: "attachments",
    component: () => import("/code/app/pages/analysis/[id]/attachments.vue")
  }
]
  },
  {
    name: _91id_935VT0XzCGlyWfOqeSVsR_smgJKmL1dhP6k4Nu_fQWGgYMeta?.name,
    path: "/et/analysis/:id()",
    component: () => import("/code/app/pages/analysis/[id].vue"),
    children: [
  {
    name: "analysis-id___et",
    path: "",
    component: () => import("/code/app/pages/analysis/[id]/index.vue")
  },
  {
    name: "analysis-id-results___et",
    path: "results",
    component: () => import("/code/app/pages/analysis/[id]/results.vue")
  },
  {
    name: "analysis-id-attachments___et",
    path: "attachments",
    component: () => import("/code/app/pages/analysis/[id]/attachments.vue")
  }
]
  },
  {
    name: "dataset___en",
    path: "/en/dataset",
    component: () => import("/code/app/pages/dataset/index.vue")
  },
  {
    name: "dataset___et___default",
    path: "/dataset",
    component: () => import("/code/app/pages/dataset/index.vue")
  },
  {
    name: "dataset___et",
    path: "/et/dataset",
    component: () => import("/code/app/pages/dataset/index.vue")
  },
  {
    name: _91id_931HA7tbjdgmwZOXlMsOBBohLabdbP9gOi35P4AhL5Cj4Meta?.name,
    path: "/en/locality/:id()",
    component: () => import("/code/app/pages/locality/[id].vue"),
    children: [
  {
    name: "locality-id___en",
    path: "",
    component: () => import("/code/app/pages/locality/[id]/index.vue")
  },
  {
    name: "locality-id-graphs___en",
    path: "graphs",
    component: () => import("/code/app/pages/locality/[id]/graphs.vue")
  },
  {
    name: "locality-id-fossils___en",
    path: "fossils",
    component: () => import("/code/app/pages/locality/[id]/fossils.vue")
  },
  {
    name: "locality-id-samples___en",
    path: "samples",
    component: () => import("/code/app/pages/locality/[id]/samples.vue")
  },
  {
    name: "locality-id-analyses___en",
    path: "analyses",
    component: () => import("/code/app/pages/locality/[id]/analyses.vue")
  },
  {
    name: "locality-id-synonyms___en",
    path: "synonyms",
    component: () => import("/code/app/pages/locality/[id]/synonyms.vue")
  },
  {
    name: "locality-id-specimens___en",
    path: "specimens",
    component: () => import("/code/app/pages/locality/[id]/specimens.vue")
  },
  {
    name: "locality-id-references___en",
    path: "references",
    component: () => import("/code/app/pages/locality/[id]/references.vue")
  },
  {
    name: "locality-id-attachments___en",
    path: "attachments",
    component: () => import("/code/app/pages/locality/[id]/attachments.vue")
  },
  {
    name: "locality-id-stratotypes___en",
    path: "stratotypes",
    component: () => import("/code/app/pages/locality/[id]/stratotypes.vue")
  },
  {
    name: "locality-id-descriptions___en",
    path: "descriptions",
    component: () => import("/code/app/pages/locality/[id]/descriptions.vue")
  },
  {
    name: "locality-id-drillcore-boxes___en",
    path: "drillcore-boxes",
    component: () => import("/code/app/pages/locality/[id]/drillcore-boxes.vue")
  }
]
  },
  {
    name: _91id_931HA7tbjdgmwZOXlMsOBBohLabdbP9gOi35P4AhL5Cj4Meta?.name,
    path: "/locality/:id()",
    component: () => import("/code/app/pages/locality/[id].vue"),
    children: [
  {
    name: "locality-id___et___default",
    path: "",
    component: () => import("/code/app/pages/locality/[id]/index.vue")
  },
  {
    name: "locality-id-graphs___et___default",
    path: "graphs",
    component: () => import("/code/app/pages/locality/[id]/graphs.vue")
  },
  {
    name: "locality-id-fossils___et___default",
    path: "fossils",
    component: () => import("/code/app/pages/locality/[id]/fossils.vue")
  },
  {
    name: "locality-id-samples___et___default",
    path: "samples",
    component: () => import("/code/app/pages/locality/[id]/samples.vue")
  },
  {
    name: "locality-id-analyses___et___default",
    path: "analyses",
    component: () => import("/code/app/pages/locality/[id]/analyses.vue")
  },
  {
    name: "locality-id-synonyms___et___default",
    path: "synonyms",
    component: () => import("/code/app/pages/locality/[id]/synonyms.vue")
  },
  {
    name: "locality-id-specimens___et___default",
    path: "specimens",
    component: () => import("/code/app/pages/locality/[id]/specimens.vue")
  },
  {
    name: "locality-id-references___et___default",
    path: "references",
    component: () => import("/code/app/pages/locality/[id]/references.vue")
  },
  {
    name: "locality-id-attachments___et___default",
    path: "attachments",
    component: () => import("/code/app/pages/locality/[id]/attachments.vue")
  },
  {
    name: "locality-id-stratotypes___et___default",
    path: "stratotypes",
    component: () => import("/code/app/pages/locality/[id]/stratotypes.vue")
  },
  {
    name: "locality-id-descriptions___et___default",
    path: "descriptions",
    component: () => import("/code/app/pages/locality/[id]/descriptions.vue")
  },
  {
    name: "locality-id-drillcore-boxes___et___default",
    path: "drillcore-boxes",
    component: () => import("/code/app/pages/locality/[id]/drillcore-boxes.vue")
  }
]
  },
  {
    name: _91id_931HA7tbjdgmwZOXlMsOBBohLabdbP9gOi35P4AhL5Cj4Meta?.name,
    path: "/et/locality/:id()",
    component: () => import("/code/app/pages/locality/[id].vue"),
    children: [
  {
    name: "locality-id___et",
    path: "",
    component: () => import("/code/app/pages/locality/[id]/index.vue")
  },
  {
    name: "locality-id-graphs___et",
    path: "graphs",
    component: () => import("/code/app/pages/locality/[id]/graphs.vue")
  },
  {
    name: "locality-id-fossils___et",
    path: "fossils",
    component: () => import("/code/app/pages/locality/[id]/fossils.vue")
  },
  {
    name: "locality-id-samples___et",
    path: "samples",
    component: () => import("/code/app/pages/locality/[id]/samples.vue")
  },
  {
    name: "locality-id-analyses___et",
    path: "analyses",
    component: () => import("/code/app/pages/locality/[id]/analyses.vue")
  },
  {
    name: "locality-id-synonyms___et",
    path: "synonyms",
    component: () => import("/code/app/pages/locality/[id]/synonyms.vue")
  },
  {
    name: "locality-id-specimens___et",
    path: "specimens",
    component: () => import("/code/app/pages/locality/[id]/specimens.vue")
  },
  {
    name: "locality-id-references___et",
    path: "references",
    component: () => import("/code/app/pages/locality/[id]/references.vue")
  },
  {
    name: "locality-id-attachments___et",
    path: "attachments",
    component: () => import("/code/app/pages/locality/[id]/attachments.vue")
  },
  {
    name: "locality-id-stratotypes___et",
    path: "stratotypes",
    component: () => import("/code/app/pages/locality/[id]/stratotypes.vue")
  },
  {
    name: "locality-id-descriptions___et",
    path: "descriptions",
    component: () => import("/code/app/pages/locality/[id]/descriptions.vue")
  },
  {
    name: "locality-id-drillcore-boxes___et",
    path: "drillcore-boxes",
    component: () => import("/code/app/pages/locality/[id]/drillcore-boxes.vue")
  }
]
  },
  {
    name: _91id_937WmA7XzOdQOh54icqeyKbEnx63ZrJ7L_pZIzQobUeRIMeta?.name,
    path: "/en/specimen/:id()",
    component: () => import("/code/app/pages/specimen/[id].vue"),
    children: [
  {
    name: "specimen-id___en",
    path: "",
    component: () => import("/code/app/pages/specimen/[id]/index.vue")
  },
  {
    name: "specimen-id-references___en",
    path: "references",
    component: () => import("/code/app/pages/specimen/[id]/references.vue")
  },
  {
    name: "specimen-id-identifications___en",
    path: "identifications",
    component: () => import("/code/app/pages/specimen/[id]/identifications.vue")
  },
  {
    name: "specimen-id-rock-identifications___en",
    path: "rock-identifications",
    component: () => import("/code/app/pages/specimen/[id]/rock-identifications.vue")
  }
]
  },
  {
    name: _91id_937WmA7XzOdQOh54icqeyKbEnx63ZrJ7L_pZIzQobUeRIMeta?.name,
    path: "/specimen/:id()",
    component: () => import("/code/app/pages/specimen/[id].vue"),
    children: [
  {
    name: "specimen-id___et___default",
    path: "",
    component: () => import("/code/app/pages/specimen/[id]/index.vue")
  },
  {
    name: "specimen-id-references___et___default",
    path: "references",
    component: () => import("/code/app/pages/specimen/[id]/references.vue")
  },
  {
    name: "specimen-id-identifications___et___default",
    path: "identifications",
    component: () => import("/code/app/pages/specimen/[id]/identifications.vue")
  },
  {
    name: "specimen-id-rock-identifications___et___default",
    path: "rock-identifications",
    component: () => import("/code/app/pages/specimen/[id]/rock-identifications.vue")
  }
]
  },
  {
    name: _91id_937WmA7XzOdQOh54icqeyKbEnx63ZrJ7L_pZIzQobUeRIMeta?.name,
    path: "/et/specimen/:id()",
    component: () => import("/code/app/pages/specimen/[id].vue"),
    children: [
  {
    name: "specimen-id___et",
    path: "",
    component: () => import("/code/app/pages/specimen/[id]/index.vue")
  },
  {
    name: "specimen-id-references___et",
    path: "references",
    component: () => import("/code/app/pages/specimen/[id]/references.vue")
  },
  {
    name: "specimen-id-identifications___et",
    path: "identifications",
    component: () => import("/code/app/pages/specimen/[id]/identifications.vue")
  },
  {
    name: "specimen-id-rock-identifications___et",
    path: "rock-identifications",
    component: () => import("/code/app/pages/specimen/[id]/rock-identifications.vue")
  }
]
  },
  {
    name: "analysis___en",
    path: "/en/analysis",
    component: () => import("/code/app/pages/analysis/index.vue")
  },
  {
    name: "analysis___et___default",
    path: "/analysis",
    component: () => import("/code/app/pages/analysis/index.vue")
  },
  {
    name: "analysis___et",
    path: "/et/analysis",
    component: () => import("/code/app/pages/analysis/index.vue")
  },
  {
    name: _91id_93jQZ51Y7paywf2xy0RuwGqw6kxx937fr3eMdFkk9VjIQMeta?.name,
    path: "/en/drillcore/:id()",
    component: () => import("/code/app/pages/drillcore/[id].vue"),
    children: [
  {
    name: "drillcore-id-boxes___en",
    path: "boxes",
    component: () => import("/code/app/pages/drillcore/[id]/boxes.vue")
  },
  {
    name: "drillcore-id___en",
    path: "",
    component: () => import("/code/app/pages/drillcore/[id]/index.vue")
  },
  {
    name: "drillcore-id-graphs___en",
    path: "graphs",
    component: () => import("/code/app/pages/drillcore/[id]/graphs.vue")
  },
  {
    name: "drillcore-id-samples___en",
    path: "samples",
    component: () => import("/code/app/pages/drillcore/[id]/samples.vue")
  },
  {
    name: "drillcore-id-analyses___en",
    path: "analyses",
    component: () => import("/code/app/pages/drillcore/[id]/analyses.vue")
  },
  {
    name: "drillcore-id-specimens___en",
    path: "specimens",
    component: () => import("/code/app/pages/drillcore/[id]/specimens.vue")
  },
  {
    name: "drillcore-id-references___en",
    path: "references",
    component: () => import("/code/app/pages/drillcore/[id]/references.vue")
  },
  {
    name: "drillcore-id-attachments___en",
    path: "attachments",
    component: () => import("/code/app/pages/drillcore/[id]/attachments.vue")
  },
  {
    name: "drillcore-id-descriptions___en",
    path: "descriptions",
    component: () => import("/code/app/pages/drillcore/[id]/descriptions.vue")
  }
]
  },
  {
    name: _91id_93jQZ51Y7paywf2xy0RuwGqw6kxx937fr3eMdFkk9VjIQMeta?.name,
    path: "/drillcore/:id()",
    component: () => import("/code/app/pages/drillcore/[id].vue"),
    children: [
  {
    name: "drillcore-id-boxes___et___default",
    path: "boxes",
    component: () => import("/code/app/pages/drillcore/[id]/boxes.vue")
  },
  {
    name: "drillcore-id___et___default",
    path: "",
    component: () => import("/code/app/pages/drillcore/[id]/index.vue")
  },
  {
    name: "drillcore-id-graphs___et___default",
    path: "graphs",
    component: () => import("/code/app/pages/drillcore/[id]/graphs.vue")
  },
  {
    name: "drillcore-id-samples___et___default",
    path: "samples",
    component: () => import("/code/app/pages/drillcore/[id]/samples.vue")
  },
  {
    name: "drillcore-id-analyses___et___default",
    path: "analyses",
    component: () => import("/code/app/pages/drillcore/[id]/analyses.vue")
  },
  {
    name: "drillcore-id-specimens___et___default",
    path: "specimens",
    component: () => import("/code/app/pages/drillcore/[id]/specimens.vue")
  },
  {
    name: "drillcore-id-references___et___default",
    path: "references",
    component: () => import("/code/app/pages/drillcore/[id]/references.vue")
  },
  {
    name: "drillcore-id-attachments___et___default",
    path: "attachments",
    component: () => import("/code/app/pages/drillcore/[id]/attachments.vue")
  },
  {
    name: "drillcore-id-descriptions___et___default",
    path: "descriptions",
    component: () => import("/code/app/pages/drillcore/[id]/descriptions.vue")
  }
]
  },
  {
    name: _91id_93jQZ51Y7paywf2xy0RuwGqw6kxx937fr3eMdFkk9VjIQMeta?.name,
    path: "/et/drillcore/:id()",
    component: () => import("/code/app/pages/drillcore/[id].vue"),
    children: [
  {
    name: "drillcore-id-boxes___et",
    path: "boxes",
    component: () => import("/code/app/pages/drillcore/[id]/boxes.vue")
  },
  {
    name: "drillcore-id___et",
    path: "",
    component: () => import("/code/app/pages/drillcore/[id]/index.vue")
  },
  {
    name: "drillcore-id-graphs___et",
    path: "graphs",
    component: () => import("/code/app/pages/drillcore/[id]/graphs.vue")
  },
  {
    name: "drillcore-id-samples___et",
    path: "samples",
    component: () => import("/code/app/pages/drillcore/[id]/samples.vue")
  },
  {
    name: "drillcore-id-analyses___et",
    path: "analyses",
    component: () => import("/code/app/pages/drillcore/[id]/analyses.vue")
  },
  {
    name: "drillcore-id-specimens___et",
    path: "specimens",
    component: () => import("/code/app/pages/drillcore/[id]/specimens.vue")
  },
  {
    name: "drillcore-id-references___et",
    path: "references",
    component: () => import("/code/app/pages/drillcore/[id]/references.vue")
  },
  {
    name: "drillcore-id-attachments___et",
    path: "attachments",
    component: () => import("/code/app/pages/drillcore/[id]/attachments.vue")
  },
  {
    name: "drillcore-id-descriptions___et",
    path: "descriptions",
    component: () => import("/code/app/pages/drillcore/[id]/descriptions.vue")
  }
]
  },
  {
    name: "locality___en",
    path: "/en/locality",
    component: () => import("/code/app/pages/locality/index.vue")
  },
  {
    name: "locality___et___default",
    path: "/locality",
    component: () => import("/code/app/pages/locality/index.vue")
  },
  {
    name: "locality___et",
    path: "/et/locality",
    component: () => import("/code/app/pages/locality/index.vue")
  },
  {
    name: "specimen___en",
    path: "/en/specimen",
    component: () => import("/code/app/pages/specimen/index.vue")
  },
  {
    name: "specimen___et___default",
    path: "/specimen",
    component: () => import("/code/app/pages/specimen/index.vue")
  },
  {
    name: "specimen___et",
    path: "/et/specimen",
    component: () => import("/code/app/pages/specimen/index.vue")
  },
  {
    name: _91id_93ciZqBjgpC9LSbQ2Rpua_45ofZKyFRc216XyfH4joirooYMeta?.name,
    path: "/en/collection/:id()",
    component: () => import("/code/app/pages/collection/[id].vue"),
    children: [
  {
    name: "collection-id___en",
    path: "",
    component: () => import("/code/app/pages/collection/[id]/index.vue")
  },
  {
    name: "collection-id-specimens___en",
    path: "specimens",
    component: () => import("/code/app/pages/collection/[id]/specimens.vue")
  }
]
  },
  {
    name: _91id_93ciZqBjgpC9LSbQ2Rpua_45ofZKyFRc216XyfH4joirooYMeta?.name,
    path: "/collection/:id()",
    component: () => import("/code/app/pages/collection/[id].vue"),
    children: [
  {
    name: "collection-id___et___default",
    path: "",
    component: () => import("/code/app/pages/collection/[id]/index.vue")
  },
  {
    name: "collection-id-specimens___et___default",
    path: "specimens",
    component: () => import("/code/app/pages/collection/[id]/specimens.vue")
  }
]
  },
  {
    name: _91id_93ciZqBjgpC9LSbQ2Rpua_45ofZKyFRc216XyfH4joirooYMeta?.name,
    path: "/et/collection/:id()",
    component: () => import("/code/app/pages/collection/[id].vue"),
    children: [
  {
    name: "collection-id___et",
    path: "",
    component: () => import("/code/app/pages/collection/[id]/index.vue")
  },
  {
    name: "collection-id-specimens___et",
    path: "specimens",
    component: () => import("/code/app/pages/collection/[id]/specimens.vue")
  }
]
  },
  {
    name: "drillcore___en",
    path: "/en/drillcore",
    component: () => import("/code/app/pages/drillcore/index.vue")
  },
  {
    name: "drillcore___et___default",
    path: "/drillcore",
    component: () => import("/code/app/pages/drillcore/index.vue")
  },
  {
    name: "drillcore___et",
    path: "/et/drillcore",
    component: () => import("/code/app/pages/drillcore/index.vue")
  },
  {
    name: "institution-egk___en",
    path: "/en/institution/egk",
    component: () => import("/code/app/pages/institution/egk.vue")
  },
  {
    name: "institution-egk___et___default",
    path: "/institution/egk",
    component: () => import("/code/app/pages/institution/egk.vue")
  },
  {
    name: "institution-egk___et",
    path: "/et/institution/egk",
    component: () => import("/code/app/pages/institution/egk.vue")
  },
  {
    name: "institution-egt___en",
    path: "/en/institution/egt",
    component: () => import("/code/app/pages/institution/egt.vue")
  },
  {
    name: "institution-egt___et___default",
    path: "/institution/egt",
    component: () => import("/code/app/pages/institution/egt.vue")
  },
  {
    name: "institution-egt___et",
    path: "/et/institution/egt",
    component: () => import("/code/app/pages/institution/egt.vue")
  },
  {
    name: "institution-elm___en",
    path: "/en/institution/elm",
    component: () => import("/code/app/pages/institution/elm.vue")
  },
  {
    name: "institution-elm___et___default",
    path: "/institution/elm",
    component: () => import("/code/app/pages/institution/elm.vue")
  },
  {
    name: "institution-elm___et",
    path: "/et/institution/elm",
    component: () => import("/code/app/pages/institution/elm.vue")
  },
  {
    name: "institution-git___en",
    path: "/en/institution/git",
    component: () => import("/code/app/pages/institution/git.vue")
  },
  {
    name: "institution-git___et___default",
    path: "/institution/git",
    component: () => import("/code/app/pages/institution/git.vue")
  },
  {
    name: "institution-git___et",
    path: "/et/institution/git",
    component: () => import("/code/app/pages/institution/git.vue")
  },
  {
    name: "institution-tug___en",
    path: "/en/institution/tug",
    component: () => import("/code/app/pages/institution/tug.vue")
  },
  {
    name: "institution-tug___et___default",
    path: "/institution/tug",
    component: () => import("/code/app/pages/institution/tug.vue")
  },
  {
    name: "institution-tug___et",
    path: "/et/institution/tug",
    component: () => import("/code/app/pages/institution/tug.vue")
  },
  {
    name: "institution-mumu___en",
    path: "/en/institution/mumu",
    component: () => import("/code/app/pages/institution/mumu.vue")
  },
  {
    name: "institution-mumu___et___default",
    path: "/institution/mumu",
    component: () => import("/code/app/pages/institution/mumu.vue")
  },
  {
    name: "institution-mumu___et",
    path: "/et/institution/mumu",
    component: () => import("/code/app/pages/institution/mumu.vue")
  },
  {
    name: "institution-tugo___en",
    path: "/en/institution/tugo",
    component: () => import("/code/app/pages/institution/tugo.vue")
  },
  {
    name: "institution-tugo___et___default",
    path: "/institution/tugo",
    component: () => import("/code/app/pages/institution/tugo.vue")
  },
  {
    name: "institution-tugo___et",
    path: "/et/institution/tugo",
    component: () => import("/code/app/pages/institution/tugo.vue")
  },
  {
    name: _91id_935zV1u7TwHmMwDJM10NawdpH523nycfKHAOldHEze8SIMeta?.name,
    path: "/en/preparation/:id()",
    component: () => import("/code/app/pages/preparation/[id].vue"),
    children: [
  {
    name: "preparation-id-taxa___en",
    path: "taxa",
    component: () => import("/code/app/pages/preparation/[id]/taxa.vue")
  },
  {
    name: "preparation-id___en",
    path: "",
    component: () => import("/code/app/pages/preparation/[id]/index.vue")
  },
  {
    name: "preparation-id-attachments___en",
    path: "attachments",
    component: () => import("/code/app/pages/preparation/[id]/attachments.vue")
  }
]
  },
  {
    name: _91id_935zV1u7TwHmMwDJM10NawdpH523nycfKHAOldHEze8SIMeta?.name,
    path: "/preparation/:id()",
    component: () => import("/code/app/pages/preparation/[id].vue"),
    children: [
  {
    name: "preparation-id-taxa___et___default",
    path: "taxa",
    component: () => import("/code/app/pages/preparation/[id]/taxa.vue")
  },
  {
    name: "preparation-id___et___default",
    path: "",
    component: () => import("/code/app/pages/preparation/[id]/index.vue")
  },
  {
    name: "preparation-id-attachments___et___default",
    path: "attachments",
    component: () => import("/code/app/pages/preparation/[id]/attachments.vue")
  }
]
  },
  {
    name: _91id_935zV1u7TwHmMwDJM10NawdpH523nycfKHAOldHEze8SIMeta?.name,
    path: "/et/preparation/:id()",
    component: () => import("/code/app/pages/preparation/[id].vue"),
    children: [
  {
    name: "preparation-id-taxa___et",
    path: "taxa",
    component: () => import("/code/app/pages/preparation/[id]/taxa.vue")
  },
  {
    name: "preparation-id___et",
    path: "",
    component: () => import("/code/app/pages/preparation/[id]/index.vue")
  },
  {
    name: "preparation-id-attachments___et",
    path: "attachments",
    component: () => import("/code/app/pages/preparation/[id]/attachments.vue")
  }
]
  },
  {
    name: "preparation___en",
    path: "/en/preparation",
    component: () => import("/code/app/pages/preparation/index.vue")
  },
  {
    name: "preparation___et___default",
    path: "/preparation",
    component: () => import("/code/app/pages/preparation/index.vue")
  },
  {
    name: "preparation___et",
    path: "/et/preparation",
    component: () => import("/code/app/pages/preparation/index.vue")
  },
  {
    name: _91id_93B8emRGXIsJW1Fq_45VbTcxDm3rJ30Yai_9ELOkAtGhrVEMeta?.name,
    path: "/en/stratigraphy/:id()",
    component: () => import("/code/app/pages/stratigraphy/[id].vue"),
    children: [
  {
    name: "stratigraphy-id___en",
    path: "",
    component: () => import("/code/app/pages/stratigraphy/[id]/index.vue")
  },
  {
    name: "stratigraphy-id-samples___en",
    path: "samples",
    component: () => import("/code/app/pages/stratigraphy/[id]/samples.vue")
  },
  {
    name: "stratigraphy-id-subunits___en",
    path: "subunits",
    component: () => import("/code/app/pages/stratigraphy/[id]/subunits.vue")
  },
  {
    name: "stratigraphy-id-synonyms___en",
    path: "synonyms",
    component: () => import("/code/app/pages/stratigraphy/[id]/synonyms.vue")
  },
  {
    name: "stratigraphy-id-specimens___en",
    path: "specimens",
    component: () => import("/code/app/pages/stratigraphy/[id]/specimens.vue")
  },
  {
    name: "stratigraphy-id-references___en",
    path: "references",
    component: () => import("/code/app/pages/stratigraphy/[id]/references.vue")
  },
  {
    name: "stratigraphy-id-related-units___en",
    path: "related-units",
    component: () => import("/code/app/pages/stratigraphy/[id]/related-units.vue")
  }
]
  },
  {
    name: _91id_93B8emRGXIsJW1Fq_45VbTcxDm3rJ30Yai_9ELOkAtGhrVEMeta?.name,
    path: "/stratigraphy/:id()",
    component: () => import("/code/app/pages/stratigraphy/[id].vue"),
    children: [
  {
    name: "stratigraphy-id___et___default",
    path: "",
    component: () => import("/code/app/pages/stratigraphy/[id]/index.vue")
  },
  {
    name: "stratigraphy-id-samples___et___default",
    path: "samples",
    component: () => import("/code/app/pages/stratigraphy/[id]/samples.vue")
  },
  {
    name: "stratigraphy-id-subunits___et___default",
    path: "subunits",
    component: () => import("/code/app/pages/stratigraphy/[id]/subunits.vue")
  },
  {
    name: "stratigraphy-id-synonyms___et___default",
    path: "synonyms",
    component: () => import("/code/app/pages/stratigraphy/[id]/synonyms.vue")
  },
  {
    name: "stratigraphy-id-specimens___et___default",
    path: "specimens",
    component: () => import("/code/app/pages/stratigraphy/[id]/specimens.vue")
  },
  {
    name: "stratigraphy-id-references___et___default",
    path: "references",
    component: () => import("/code/app/pages/stratigraphy/[id]/references.vue")
  },
  {
    name: "stratigraphy-id-related-units___et___default",
    path: "related-units",
    component: () => import("/code/app/pages/stratigraphy/[id]/related-units.vue")
  }
]
  },
  {
    name: _91id_93B8emRGXIsJW1Fq_45VbTcxDm3rJ30Yai_9ELOkAtGhrVEMeta?.name,
    path: "/et/stratigraphy/:id()",
    component: () => import("/code/app/pages/stratigraphy/[id].vue"),
    children: [
  {
    name: "stratigraphy-id___et",
    path: "",
    component: () => import("/code/app/pages/stratigraphy/[id]/index.vue")
  },
  {
    name: "stratigraphy-id-samples___et",
    path: "samples",
    component: () => import("/code/app/pages/stratigraphy/[id]/samples.vue")
  },
  {
    name: "stratigraphy-id-subunits___et",
    path: "subunits",
    component: () => import("/code/app/pages/stratigraphy/[id]/subunits.vue")
  },
  {
    name: "stratigraphy-id-synonyms___et",
    path: "synonyms",
    component: () => import("/code/app/pages/stratigraphy/[id]/synonyms.vue")
  },
  {
    name: "stratigraphy-id-specimens___et",
    path: "specimens",
    component: () => import("/code/app/pages/stratigraphy/[id]/specimens.vue")
  },
  {
    name: "stratigraphy-id-references___et",
    path: "references",
    component: () => import("/code/app/pages/stratigraphy/[id]/references.vue")
  },
  {
    name: "stratigraphy-id-related-units___et",
    path: "related-units",
    component: () => import("/code/app/pages/stratigraphy/[id]/related-units.vue")
  }
]
  },
  {
    name: _91id_93HRl2tOM5nO9H2y6CMDaJZ7rrweqKgN8syTM_03nVlbwMeta?.name,
    path: "/en/drillcore-box/:id()",
    component: () => import("/code/app/pages/drillcore-box/[id].vue"),
    children: [
  {
    name: "drillcore-box-id___en",
    path: "",
    component: () => import("/code/app/pages/drillcore-box/[id]/index.vue")
  },
  {
    name: "drillcore-box-id-samples___en",
    path: "samples",
    component: () => import("/code/app/pages/drillcore-box/[id]/samples.vue")
  },
  {
    name: "drillcore-box-id-analyses___en",
    path: "analyses",
    component: () => import("/code/app/pages/drillcore-box/[id]/analyses.vue")
  },
  {
    name: "drillcore-box-id-specimens___en",
    path: "specimens",
    component: () => import("/code/app/pages/drillcore-box/[id]/specimens.vue")
  }
]
  },
  {
    name: _91id_93HRl2tOM5nO9H2y6CMDaJZ7rrweqKgN8syTM_03nVlbwMeta?.name,
    path: "/drillcore-box/:id()",
    component: () => import("/code/app/pages/drillcore-box/[id].vue"),
    children: [
  {
    name: "drillcore-box-id___et___default",
    path: "",
    component: () => import("/code/app/pages/drillcore-box/[id]/index.vue")
  },
  {
    name: "drillcore-box-id-samples___et___default",
    path: "samples",
    component: () => import("/code/app/pages/drillcore-box/[id]/samples.vue")
  },
  {
    name: "drillcore-box-id-analyses___et___default",
    path: "analyses",
    component: () => import("/code/app/pages/drillcore-box/[id]/analyses.vue")
  },
  {
    name: "drillcore-box-id-specimens___et___default",
    path: "specimens",
    component: () => import("/code/app/pages/drillcore-box/[id]/specimens.vue")
  }
]
  },
  {
    name: _91id_93HRl2tOM5nO9H2y6CMDaJZ7rrweqKgN8syTM_03nVlbwMeta?.name,
    path: "/et/drillcore-box/:id()",
    component: () => import("/code/app/pages/drillcore-box/[id].vue"),
    children: [
  {
    name: "drillcore-box-id___et",
    path: "",
    component: () => import("/code/app/pages/drillcore-box/[id]/index.vue")
  },
  {
    name: "drillcore-box-id-samples___et",
    path: "samples",
    component: () => import("/code/app/pages/drillcore-box/[id]/samples.vue")
  },
  {
    name: "drillcore-box-id-analyses___et",
    path: "analyses",
    component: () => import("/code/app/pages/drillcore-box/[id]/analyses.vue")
  },
  {
    name: "drillcore-box-id-specimens___et",
    path: "specimens",
    component: () => import("/code/app/pages/drillcore-box/[id]/specimens.vue")
  }
]
  },
  {
    name: "stratigraphy___en",
    path: "/en/stratigraphy",
    component: () => import("/code/app/pages/stratigraphy/index.vue")
  },
  {
    name: "stratigraphy___et___default",
    path: "/stratigraphy",
    component: () => import("/code/app/pages/stratigraphy/index.vue")
  },
  {
    name: "stratigraphy___et",
    path: "/et/stratigraphy",
    component: () => import("/code/app/pages/stratigraphy/index.vue")
  },
  {
    name: "analytical-data___en",
    path: "/en/analytical-data",
    component: () => import("/code/app/pages/analytical-data/index.vue")
  },
  {
    name: "analytical-data___et___default",
    path: "/analytical-data",
    component: () => import("/code/app/pages/analytical-data/index.vue")
  },
  {
    name: "analytical-data___et",
    path: "/et/analytical-data",
    component: () => import("/code/app/pages/analytical-data/index.vue")
  },
  {
    name: component_45stub2RK8NI9EPuQlDWCpb2ZVFhMfEk0tUJyI05LzPSN8YDUMeta?.name,
    path: "/en/sitemap.xml",
    component: component_45stub2RK8NI9EPuQlDWCpb2ZVFhMfEk0tUJyI05LzPSN8YDU
  },
  {
    name: component_45stub2RK8NI9EPuQlDWCpb2ZVFhMfEk0tUJyI05LzPSN8YDUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub2RK8NI9EPuQlDWCpb2ZVFhMfEk0tUJyI05LzPSN8YDU
  },
  {
    name: component_45stub2RK8NI9EPuQlDWCpb2ZVFhMfEk0tUJyI05LzPSN8YDUMeta?.name,
    path: "/et/sitemap.xml",
    component: component_45stub2RK8NI9EPuQlDWCpb2ZVFhMfEk0tUJyI05LzPSN8YDU
  }
]