import { isString } from "@intlify/shared";
import { useCookie, useNuxtApp, useRequestHeader, useRuntimeConfig } from "#imports";
import { DEFAULT_COOKIE_KEY, isSSG, localeCodes, normalizedLocales } from "#build/i18n.options.mjs";
import { findBrowserLocale, regexpPath } from "./routing/utils.js";
import { initCommonComposableOptions } from "./utils.js";
import { createLogger } from "#nuxt-i18n/logger";
export function wrapComposable(fn, common = initCommonComposableOptions()) {
  return (...args) => fn(common, ...args);
}
function parseAcceptLanguage(input = "") {
  return input.split(",").map((tag) => tag.split(";")[0]);
}
export function getBrowserLocale() {
  const browserLocales = import.meta.client ? navigator.languages : parseAcceptLanguage(useRequestHeader("accept-language"));
  return findBrowserLocale(normalizedLocales, browserLocales) || void 0;
}
export function createI18nCookie() {
  const detect = runtimeDetectBrowserLanguage();
  const cookieKey = detect && detect.cookieKey || DEFAULT_COOKIE_KEY;
  const date = /* @__PURE__ */ new Date();
  const cookieOptions = {
    path: "/",
    readonly: false,
    expires: new Date(date.setDate(date.getDate() + 365)),
    sameSite: detect && detect.cookieCrossOrigin ? "none" : "lax",
    domain: detect && detect.cookieDomain || void 0,
    secure: detect && detect.cookieCrossOrigin || detect && detect.cookieSecure
  };
  return useCookie(cookieKey, cookieOptions);
}
export function getLocaleCookie(cookieRef, detect, defaultLocale) {
  const logger = /* @__PURE__ */ createLogger(`getLocaleCookie:${import.meta.client ? "client" : "server"}`);
  __DEBUG__ && logger.log({
    useCookie: detect && detect.useCookie,
    cookieKey: detect && detect.cookieKey,
    localeCodes
  });
  if (detect === false || !detect.useCookie) {
    return;
  }
  const localeCode = cookieRef.value ?? void 0;
  if (localeCode == null) {
    __DEBUG__ && logger.log(`none`);
    return;
  }
  if (localeCodes.includes(localeCode)) {
    __DEBUG__ && logger.log(`locale from cookie: `, localeCode);
    return localeCode;
  }
  if (defaultLocale) {
    __DEBUG__ && logger.log(`unknown locale cookie (${localeCode}), setting to defaultLocale (${defaultLocale})`);
    cookieRef.value = defaultLocale;
    return defaultLocale;
  }
  __DEBUG__ && logger.log(`unknown locale cookie (${localeCode}), unsetting cookie`);
  cookieRef.value = void 0;
}
export function detectBrowserLanguage(route, localeCookie, locale = "") {
  const logger = /* @__PURE__ */ createLogger("detectBrowserLanguage");
  const _detect = runtimeDetectBrowserLanguage();
  if (!_detect) {
    return { locale: "", error: "disabled" };
  }
  const nuxtApp = useNuxtApp();
  const strategy = nuxtApp.$i18n.strategy;
  const firstAccess = nuxtApp._vueI18n.__firstAccess;
  __DEBUG__ && logger.log({ firstAccess });
  if (isSSG && firstAccess && strategy === "no_prefix" && import.meta.server) {
    return { locale: "", error: "detect_ignore_on_ssg" };
  }
  if (!firstAccess) {
    return { locale: strategy === "no_prefix" ? locale : "", error: "first_access_only" };
  }
  __DEBUG__ && logger.log({ locale, path: isString(route) ? route : route.path, strategy, ..._detect });
  if (strategy !== "no_prefix") {
    const path = isString(route) ? route : route.path;
    if (_detect.redirectOn === "root" && path !== "/") {
      return { locale: "", error: "not_redirect_on_root" };
    }
    if (_detect.redirectOn === "no prefix" && !_detect.alwaysRedirect && path.match(regexpPath)) {
      return { locale: "", error: "not_redirect_on_no_prefix" };
    }
  }
  const cookieMatch = _detect.useCookie && localeCookie || void 0;
  if (cookieMatch) {
    return { locale: cookieMatch, from: "cookie" };
  }
  const browserMatch = nuxtApp.$i18n.getBrowserLocale();
  if (browserMatch) {
    return { locale: browserMatch, from: "navigator_or_header" };
  }
  return { locale: _detect.fallbackLocale || "", from: "fallback" };
}
export function runtimeDetectBrowserLanguage(opts = useRuntimeConfig().public.i18n) {
  if (opts?.detectBrowserLanguage === false) return false;
  return opts?.detectBrowserLanguage;
}
